function findSoundIndex(soundList, soundNode) {
  for (let index = 0; index < soundList.length; index += 1) {
    if (soundList[index] === soundNode) {
      return index;
    }
  }
  return null;
}

let currentWord = null;
let currentWordCopy = null;
let currentSoundIndex = -1;

function clearFocusWord() {
  currentWord = null;
  currentSoundIndex = -1;
  if (currentWordCopy !== null) {
    const popupElement = document.getElementById('epc-popup');
    popupElement.removeChild(currentWordCopy);
    $(popupElement).hide();
    currentWordCopy = null;
  }
}

function setFocusWord(wordNode, soundIndex) {
  // Clear the current word, if it's changed
  if (currentWord !== null && currentWord !== wordNode) {
    clearFocusWord();
  }

  // If we don't have an active pop-up, create one
  if (currentWord === null) {
    // New word - we need to set up our magnified clone from scratch
    currentWord = wordNode;
    currentWordCopy = wordNode.cloneNode(true);
    const popupElement = document.getElementById('epc-popup');
    popupElement.appendChild(currentWordCopy);

    // Re-position the popup
    const wordPosition = $(currentWord).offset();
    const wordHeight = $(currentWord).height();
    $(popupElement).css({
      left: `${wordPosition.left - 16}px`,
      top: `${wordPosition.top + wordHeight + 8}px`,
    });
    $(popupElement).show();
  }

  // If we already have an active element, deactivate it
  if (currentSoundIndex !== -1 && currentSoundIndex !== soundIndex) {
    currentWordCopy.childNodes[currentSoundIndex].classList.remove('epc-active');
    currentSoundIndex = -1;
  }

  // Activate the correct element
  if (currentSoundIndex !== soundIndex) {
    currentSoundIndex = soundIndex;
    currentWordCopy.childNodes[currentSoundIndex].classList.add('epc-active');
  }
}

$(() => {
  const popupElement = document.createElement('div');
  popupElement.id = 'epc-popup';
  popupElement.innerHTML =
    '<ruby class="epc-active"><rb>&#8203;</rb><rt>&#8203;&#8203;</rt></ruby>';
  document.body.appendChild(popupElement);
  $(popupElement).hide();
});

let hideTimer = null;

$('body').on('mouseenter', '[data-sounds]', event => {
  // Locate the word this sound is a part of
  const partNode = event.currentTarget;
  const wordNode = partNode.parentNode;

  // Find out which sound we've hovered over
  const soundIndex = findSoundIndex(wordNode.childNodes, partNode);
  if (soundIndex === null) {
    return;
  }

  // Set the focused word
  setFocusWord(wordNode, soundIndex);

  // Disable the hide timer
  clearTimeout(hideTimer);
  hideTimer = null;
});

function hideBox() {
  hideTimer = null;
  clearFocusWord();
}

$('body').on('mouseleave', '[data-sounds]', () => {
  if (hideTimer !== null) {
    clearTimeout(hideTimer);
  }
  hideTimer = setTimeout(hideBox, 400);
});

$('#epc-popup').on('mouseenter', () => {
  if (hideTimer !== null) {
    clearTimeout(hideTimer);
  }
  clearFocusWord();
});
