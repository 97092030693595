import 'lib/popup';
import 'css/index.scss';
import './browserStyle';
import './fontawesome';

/* eslint-disable-next-line prefer-arrow-callback,func-name */
$(window).scroll(() => {
  if ($(document).scrollTop() > 30) {
    $('nav').addClass('shrink');
  } else {
    $('nav').removeClass('shrink');
  }
});

function insertFacebook() {
  // Don't do this if it's already done
  if (document.getElementById('facebook-jssdk')) {
    return;
  }

  // Insert the Facebook SDK JavaScript
  const js = document.createElement('script');
  js.id = 'facebook-jssdk';
  js.src = 'https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v3.1';

  const fjs = document.getElementsByTagName('script')[0];
  fjs.parentNode.insertBefore(js, fjs);
}

let lastWidth = 0;
let lastHeight = 0;

function resizeFacebook() {
  // Bail if we don't have a Facebook page widget
  if ($('.fb-page').length === 0) {
    return;
  }

  // Calculate size of Facebook widget
  const containerWidth = $('.fb-container').innerWidth();
  const containerHeight = 600;

  if (lastWidth !== containerWidth || lastHeight !== containerHeight) {
    lastWidth = containerWidth;
    lastHeight = containerHeight;

    // Apply to the DOM
    $('.fb-page')
      .attr('data-width', containerWidth)
      .attr('data-height', containerHeight);

    // Resize the thing
    window.setTimeout(() => {
      if (FB) {
        FB.XFBML.parse();
      }
    }, 500);
  }
}

window.fbAsyncInit = () => {
  resizeFacebook();
  FB.init({
    appId: 'your-app-id',
    autoLogAppEvents: true,
    xfbml: true,
    version: 'v3.1',
  });
};

$(document).ready(() => {
  // If we have a Facebook page, load the Facebook SDK
  if ($('.fb-page').length > 0) {
    insertFacebook();
    $(window).resize(resizeFacebook);
  }
});
