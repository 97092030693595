const BrowserDetect = {
  init() {
    this.browser = this.searchString(this.dataBrowser) || 'Other';
    this.version =
      this.searchVersion(navigator.userAgent) ||
      this.searchVersion(navigator.appVersion) ||
      'Unknown';
  },
  searchString(data) {
    for (let i = 0; i < data.length; i += 1) {
      const dataString = data[i].string;
      this.versionSearchString = data[i].subString;

      if (dataString.indexOf(data[i].subString) !== -1) {
        return data[i].identity;
      }
    }
    return false;
  },
  searchVersion(dataString) {
    const index = dataString.indexOf(this.versionSearchString);
    if (index === -1) {
      return false;
    }

    const rv = dataString.indexOf('rv:');
    if (this.versionSearchString === 'Trident' && rv !== -1) {
      return parseFloat(dataString.substring(rv + 3));
    }
    return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
  },

  dataBrowser: [
    { string: navigator.userAgent, subString: 'Edge', identity: 'MS Edge' },
    { string: navigator.userAgent, subString: 'MSIE', identity: 'Explorer' },
    { string: navigator.userAgent, subString: 'Trident', identity: 'Explorer' },
    { string: navigator.userAgent, subString: 'Firefox', identity: 'Firefox' },
    { string: navigator.userAgent, subString: 'Opera', identity: 'Opera' },
    { string: navigator.userAgent, subString: 'OPR', identity: 'Opera' },

    { string: navigator.userAgent, subString: 'Chrome', identity: 'Chrome' },
    { string: navigator.userAgent, subString: 'Safari', identity: 'Safari' },
  ],
};

BrowserDetect.init();

const bv = BrowserDetect.browser;
if (bv === 'Chrome') {
  $('body').addClass('chrome');
  $('body').addClass('webkit');
} else if (bv === 'Safari') {
  $('body').addClass('safari');
  $('body').addClass('webkit');
} else if (bv === 'MS Edge') {
  $('body').addClass('edge');
} else if (bv === 'Explorer') {
  $('body').addClass('ie');
} else if (bv === 'Firefox') {
  $('body').addClass('Firefox');
}
